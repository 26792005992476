import { useDraftPromoStore } from '~/stores/draftPromo'
import { useMiscDraftProgressivePromosStore } from '~/stores/miscDraftProgressivePromos'

export default defineNuxtRouteMiddleware(async () => {
  const { $pinia } = useNuxtApp()

  const miscDraftProgressivePromosStore =
    useMiscDraftProgressivePromosStore($pinia)
  const draftPromoStore = useDraftPromoStore($pinia)

  await miscDraftProgressivePromosStore.FETCH()
  await draftPromoStore.CHECK_FOR_PROGRESSIVE_PROMO()
})
